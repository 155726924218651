@layer modules, ui, base;
@layer base {
  .StudiesInfoForm_main__p8Nai {
  position: relative;
}

.StudiesInfoForm_header__gDjTY {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
  padding: var(--sk-space-40) 0;
  text-align: center;
}

.StudiesInfoForm_footer__vs0Af {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  border: none;
  background-color: var(--sk-color-white);
}

.StudiesInfoForm_footer__vs0Af button {
  width: calc(100% - calc(var(--sk-space-16) * 2));
  margin: var(--sk-space-16) 0;
}

.StudiesInfoForm_fields__6x1RY {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
  margin-bottom: var(--sk-space-56);
  padding-bottom: 16vh;
}

@media (min-width: 768px) {
  .StudiesInfoForm_highlighted_choices__227L8 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .StudiesInfoForm_highlighted_choices__227L8 > :first-child {
    grid-column: 1 / 3;
  }

  .StudiesInfoForm_fields__6x1RY {
    padding-bottom: var(--sk-space-72);
  }
}

}
@layer base {
  .OnboardingModal_head__gTYLm {
  display: flex;
  position: fixed;
  z-index: calc(
    var(--z-up-in-the-current-stacking-context) + 1
  ); /* to have the head above the form fields AND above the upload file button */
  top: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  padding: var(--sk-space-16) 0;
  gap: var(--sk-space-8);
  background-color: var(--sk-color-white);
}

.OnboardingModal_localeSwitcher__tw996 {
  position: absolute;
  right: var(--sk-space-32);
}

}
