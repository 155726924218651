@layer base {
  .main {
  position: relative;
}

.header {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
  padding: var(--sk-space-40) 0;
  text-align: center;
}

.footer {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  border: none;
  background-color: var(--sk-color-white);
}

.footer button {
  width: calc(100% - calc(var(--sk-space-16) * 2));
  margin: var(--sk-space-16) 0;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
  margin-bottom: var(--sk-space-56);
  padding-bottom: 16vh;
}

@media (--sk-medium-viewport) {
  .highlighted_choices {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .highlighted_choices > :first-child {
    grid-column: 1 / 3;
  }

  .fields {
    padding-bottom: var(--sk-space-72);
  }
}

}