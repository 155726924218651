@layer base {
  .head {
  display: flex;
  position: fixed;
  z-index: calc(var(--z-up-in-the-current-stacking-context) + 1); /* to have the head above the form fields AND above the upload file button */
  top: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  padding: var(--sk-space-16) 0;
  gap: var(--sk-space-8);
  background-color: var(--sk-color-white);
}

.localeSwitcher {
  position: absolute;
  right: var(--sk-space-32);
}

}